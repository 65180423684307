import { datadogRum } from '@datadog/browser-rum'

const env = import.meta.env.VITE_ENV
const version = import.meta.env.VITE_BUILD_VERSION

const initDatadog = ({ env }: { env: string }) => {
  if (env === 'development') return

  datadogRum.init({
    applicationId: '8732d44b-c01b-42f5-9be6-33d34a3fbe36',
    clientToken: 'pubf1fd3fe1d6564ad92f8c67c924705d1e',
    site: 'datadoghq.com',
    service: 'calsg',
    env,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}

initDatadog({ env })
